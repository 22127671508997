.About {
  min-width: 100%;
}

.About-container {
  width: 80%;
  margin: 0px auto;
  margin-top: 3rem;
  min-height: 540px;
}

.About-profile {
  display: flex;
  flex-direction: column;
}

.About-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
}

.profileImage {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 2rem;
}

.About-title-name {
   font-family: Outfit;
  font-size: 27px;
  font-weight: bold;
}

.About-title-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

.About-resume {
   font-family: Outfit;
  text-decoration: none;
  display: block;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}

.About-body {
  margin-top: 2rem;
  align-self: center;
  width: 60%;
}

.About-body h4 {
   font-family: Outfit;
  font-size: 18px;
  font-weight: 400;

}

.About-body-title {
  font-family: Outfit;
  margin-bottom: 16px;
  font-size: 27px;
  font-weight: 600;

}

.Quick-link {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  height: 120px;
}

.Quick-link-title {
   font-family: Outfit;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.aboutContactLink {
  text-decoration: none;
  margin-left: 20px;
  transition: all 200ms ease-in-out;
}

.aboutContactLink :active,
.aboutContactLink :hover {
  text-decoration: none;
  color: #f4678e;
}

/* tablet */
@media (max-width: 768px) {
  .About-body {
    width: 80%;
  }

  .About-header {
    flex-direction: column;
    justify-content: center;
  }

  .profileImage {
    width: 10rem;
    height: 10rem;
  }

  .About-header-text {
    text-align: center;
    line-height: 0.8cm;
    margin-top: 10px;
  }

  .About-body h4 {
    font-size: 16px;
  }
}

/* lg mobile*/
@media (max-width: 560px) {
  .About-header-text {
    text-align: center;
    line-height: 0.8cm;
    margin-top: 10px;
  }
  .About-body {
    width: 100%;
  }
}
