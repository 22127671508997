.e404 {
  display: flex;
  width: 100%;
  height: calc(100vh - 120px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.e404Image {
  width: 18rem;
  height: auto;
}

.e404Text {
  font-size: 18px;
  font-weight: 400;
  margin-top: 35px;
}

.E-home-link {
  text-decoration: none;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
}

.e404Icon {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .e404Image {
    width: 15rem;
  }

  .e404Text,
  .E-home-link {
    font-size: 15px;
  }
}

@media (max-width: 560px) {
  .e404Image {
    width: 10rem;
  }
  .e404Text,
  .E-home-link {
    font-size: 13px;
  }
}
