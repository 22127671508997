.bottomDrawer {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: none;
}

.bottomDrawerOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.bottomDrawerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
  padding: 0px 2rem;
  z-index: 10;
}

.bottomDrawerTitle {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.bottomDrawerTitleDiff {
  font-size: 13px;
  font-weight: 600;
}

.bottomDrawerActions {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  height: 50%;
}

.bottomDrawerIcon {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomDrawerIcon .Bottom-drawer-icon-text {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
}

.btnClose {
  font-family: Audiowide;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 25px;
  top: 10%;
  background: transparent;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
  padding: 8px;
}

@media (max-width: 768px) {
  .bottomDrawer {
    display: block;
  }
}
