
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-weight: 400;
  box-sizing: border-box;
}
body {
  font-family: Outfit;
  width: 100%;
}
