.Home {
  width: 100%;
  position: relative;
}

.Home-container {
  width: 80%;
  display: flex;
  height: auto;
  min-height: 540px;
  margin: 0px auto;
  justify-content: space-around;
  align-items: center;
  flex:3
}

.Home-contents .Whoami h4 {
   font-family: Outfit;
  font-size: 27px;
  cursor: pointer;
}

.Home-contents .Whoami .Cv {
   font-family: Outfit;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 27px;
}

.Tech-stacks {
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.Tech-stacks .Title {
   font-family: Outfit;
  line-height: 18px;
  font-size: 18px;
  font-weight: bold;
}

.Tech-stack-label, .Tech-stack-label span {
   font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
}

.Tech-stack-header {
  margin: 16px 0px;
}

.Dark {
  color: #aaa;
}

.Dark-active {
  color: #efb086;
}

.Light {
  color: #333;
}

.Light-active {
  color: #3b5998;
}

.Tech-stack-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 16px;
  text-align: center;
}

.Tech-stack-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.Tech-stack-list-item:hover .Tech-stack-list-icon {
  transform: scale(1.3);
  transition: transform 200ms ease-in;
}

.Tech-stack-list-text {
   font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
}

.Home-action {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: none;
  overflow: hidden;
}

.Footer {
  flex:1;
  width: 80%;
  margin: 0px auto;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Footer-text {
   font-family: Outfit;
  color: #aaaaaa;
  cursor: pointer;
  font-size: 16;
  font-weight:bold;
}

@media (max-width: 768px) {
  .Tech-stacks {
    width: 90%;
  }

  .Home-contents .Whoami h4 {
    font-size: 23px;
    font-weight: 600;
  }

  .Home-action {
    display: block;
  }

}

@media (max-width: 540px) {
  .Tech-stacks {
    width: 100%;
  }

  .Home-contents .Whoami h4 {
    font-size: 20px;
  }

  .Tech-stack-list-text {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
  }
}
