.Appbar {
  height: 120px;
  border: 0;
}

.Appbar-container {
  width: 80%;
  height: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  border: 0;
}

.Appbar .Left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Appbar .Appbar-title {
  text-decoration: none;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 16px;
  transition: all 200ms ease;
}

.Appbar-title:active,
.Appbar-title:hover,
.Appbar-socials a:hover {
  color: #ff9671;
}

.Appbar .Appbar-socials {
  display: flex;
  justify-content: center;
}

.Appbar-socials a {
  display: inline-block;
  text-decoration: none;
  margin-left: 16px;
  font-size: 22px;
  font-weight: 200;
  transition: all 200ms ease;
}

.Appbar .Right {
  align-self: center;
}

.Appbar .Right button {
  font-size: 35px;
  background: transparent;
  border: 0;
}

/* tablet */
@media (max-width: 768px) {
  .Appbar .Appbar-title {
    font-size: 24px;
  }

  .Appbar .Appbar-socials a {
    font-size: 18px;
  }

  .Appbar .Right button {
    font-size: 27px;
  }
}

/* mobile lg */
@media (max-width: 540px) {
  .Appbar-container {
    justify-content: space-between;
  }

  .Appbar .Appbar-title {
    font-size: 26px;
    margin-bottom: 10;
  }
}
